<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <h1 class="title">{{$t(`pages.locations.title`)}}</h1>
            <div class="cta-container with-text">
                <div :class="['cta cta-primary cta-slim']" @click="check_if_allowed()">{{$t(`pages.locations.create.ctas.create`)}}</div>
            </div>
        </div>
        
        <div id="with-sticky-header" class="table-container with-sticky-header">
            <table class="nomargin-bottom">
                <tr>
                    <th>{{$t('table.locations.th.name')}}</th>
                    <th>{{$t('table.locations.th.address')}}</th>
                </tr>

                <tr v-for="(location, index) in locations" :id="`location-${index}`" :key="location._id" @click="goToLocation(location._id)">
                    <td class="bold">{{ location.name }}</td>
                    <td>{{ location.formattedAddress }}</td>
                </tr>
                
                <tr class="no-hover">
                    <td v-if="!loaded" class="loading" colspan="2"></td>
                    <td v-if="loaded && locations.length === 0" class="bold" colspan="2">{{$t('table.locations.empty')}}</td>
                </tr>
            </table>
        </div>
    </layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { bus } from '../../../main';
import Layout from '../../../components/Layout.vue';

export default {
    name: 'Locations',

    components: {
        Layout
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        type: {
            type: String,
            default: 'work-provider'
        }
    },

    data() {
        return {
            routeName: 'locations',
            classes: { dashboard: true },

            locations: [],
            cant_create_more: false,
            loaded: false
        }
    },

    computed: {
        ...mapGetters([
            'has_payment_method'
        ]),
    },

    methods: {
        ...mapActions(['set_payment_method_needed_modal_state']),

        getLocations: function() {
            const self = this;

            self.$axios
                .get(`locations`)
                .then(response => {
                    if(response.status == 200){
                        self.locations = response.data.locations;
                        self.cant_create_more = response.data.cant_create_more;
                        self.loaded = true;

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        goToLocation: function(id) {
            const self = this; self.$utils._navigate_to_name_with_params(`update-location`, { id: id, type: 'work-provider' })
        },

        check_if_allowed: function() {
            const self = this;
                
            self.$utils._navigate_to_name_with_params('create-location', { type: self.type })

            // if(self.has_payment_method)
            //     self.$utils._navigate_to_name_with_params('create-location', { type: self.type })
            // else {
            //     self.set_payment_method_needed_modal_state(true);
            // }
        }
    },

    mounted() {
        const self = this; self.getLocations();
    }
}
</script>

